import React, {Component, Fragment} from 'react';
import "./MenuView.css";

// import Form from 'react-bootstrap/Form'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Alert from 'react-bootstrap/Alert'

class MenuView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pozoDefault: "Seleccione un pozo",
      wasOperated: false
    }
    this.handlePozosChange = this.handlePozosChange.bind(this);
  }

  handlePozosChange(k) {
    // console.log(k);
    this.setState({ wasOperated: true });
    this.props.onPozosChange(k);
  }

  getPozoName() {
    // console.log(this.props.pozoSeleccionado);
    if (Object.entries(this.props.pozoSeleccionado).length === 0 && this.props.pozoSeleccionado.constructor === Object)
      return this.state.pozoDefault;
    else
      return this.props.pozoSeleccionado.descripcion;
  }

  render() {
    return (
      <div className="MenuView">
        {!(Object.entries(this.props.dashboard).length === 0 && this.props.dashboard.constructor === Object) && ( // ? (
        //   <Alert key="aviso" variant='secondary'>
        //     Debe iniciar sesion para ver sus pozos
        //   </Alert>
        // ) : (
          <DropdownButton title={this.getPozoName()} id="dropdown-pozos" className="dropdown-pozos" onSelect={this.handlePozosChange}> 
            <Dropdown.Item key="0" eventKey="0">{this.state.pozoDefault}</Dropdown.Item>
            <Dropdown.Divider />
            {this.props.dashboard.map(
											(pozo) => <Dropdown.Item key={pozo.id_pozo} eventKey={pozo.id_pozo}>{pozo.descripcion}</Dropdown.Item> )
            }
          </DropdownButton>
        )}
        {(this.state.wasOperated) && (Object.entries(this.props.pozoSeleccionado).length === 0 && this.props.pozoSeleccionado.constructor === Object) && (
          <Alert key="table-warning" className="table-message" variant='warning'>
            Debe seleccionar un pozo
          </Alert>
        )}
        {!(Object.entries(this.props.pozoSeleccionado).length === 0 && this.props.pozoSeleccionado.constructor === Object) && (
          <Alert key="table-ok" className="table-message" variant='info'>
              {`codigo_expediente : ${this.props.pozoSeleccionado.codigo_expediente} numero_solicitud : ${this.props.pozoSeleccionado.numero_solicitud}`}
          </Alert>
        )}
      </div>
    );
  }
}

export default MenuView;
import React, {Component, Fragment} from 'react';
import "./LoginView.css";

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'

class LoginView extends Component {
  constructor(props) {
    super(props);

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleEmailChange(k) {
    this.props.onEmailChange(k.target.value);
  }

  handlePasswordChange(k) {
    this.props.onPasswordChange(k.target.value);
  }
  
  handleSubmit(event) {
    event.preventDefault();
    this.props.onLoginSubmit();
  }

  render() {
    return (
      <div className="LoginView">
          <Form>
            <Form.Group controlId="formBasicEmail">
              { (this.props.badPassword) && (  <Alert key="badPassword" variant="danger">
                                      Password invalido
                                    </Alert>) }
              <Form.Label>Correo Electrónico</Form.Label>
              <Form.Control type="email" placeholder="Ingrese su correo electrónico" value={this.props.email} onChange={this.handleEmailChange}/>
              {/* <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text> */}
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Ingrese su Password" value={this.props.password} onChange={this.handlePasswordChange}/>
            </Form.Group>
            {/* <Form.Group controlId="formBasicCheckbox">
              <Form.Check type="checkbox" label="Check me out" />
            </Form.Group> */}
            <Button type="submit" variant="primary" onClick={this.handleSubmit} >
              Entrar
            </Button>
          </Form>
      </div>
    );
  }
}

export default LoginView;
export async function rest_login(json) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: json,
        redirect: 'follow'
    };
    
    return fetch("https://api.001919.xyz/api/sesiones/login", requestOptions)
            .then(response => response.text());
}

export async function rest_validateToken(json) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: json,
        redirect: 'follow'
    };
    
    return fetch("https://api.001919.xyz/api/sesiones/validateToken", requestOptions)
            .then(response => response.text());
}

export async function rest_getDashboard(token) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization",`Bearer ${token}`);
    
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    
    return fetch("https://api.001919.xyz/api/sesiones/dashboard", requestOptions)
            .then(response => response.text());
}

export async function rest_getDatos(token, pozo, paginado) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization",`Bearer ${token}`);
    
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    
    // return fetch(`https://api.001919.xyz/api/datos?fk_pozo=${pozo.id_pozo}`, requestOptions)
    return fetch(`https://api.001919.xyz/api/sesiones/datos?codigo_expediente=${pozo.codigo_expediente}&numero_solicitud=${pozo.numero_solicitud}&resultados=${paginado.resultados}&pagina=${paginado.pagina}`, requestOptions)
            .then(response => response.text());
}
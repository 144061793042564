export function uint16_to_int16(value) {
    // console.log(`OLD Value : ${value}`);
    var buffer = new ArrayBuffer(2);
    var dataview = new DataView(buffer);
    dataview.setUint16(0, value, true);
    let newValue = dataview.getInt16(0, true);
    // console.log(`NEW Value : ${newValue}`);
    // let buf4 = Buffer.from(buffer);
    // console.log(buf4);
    return newValue;
}

export function uint16x2_to_long(a, b) {
    var buffer = new ArrayBuffer(4);
    var dataview = new DataView(buffer);
    dataview.setUint16(0, a, true);
    dataview.setUint16(2, b, true);
    let newValue = dataview.getInt32(0, true);
    return newValue;
}

export function convertToIEEE754(a, b) {
    var ieee754 = require('ieee754');
    var buffer = Buffer.alloc(4);
    buffer.writeUInt16LE(a, 0);
    buffer.writeUInt16LE(b, 2);
    // console.log(buf);
    var num = ieee754.read(buffer, 0, true, 23, 4);
    // console.log(num);
    return num;
}

export function convertToBits(a) {
    // Cambiar a formato arreglo
    // let arr = [...a.toString(2).padStart(16,'0')];
    // var arr_ok = arr.map(function(x) {
    //     return (x === '1') ? 1 : 0;
    //  });
    // return arr_ok;
    return a.toString(2).padStart(16,'0');
}

export function parsePro(json) {
    let myJson = {};
    if ((json.REG0001 !== undefined) && (json.REG0002 !== undefined))
        myJson.r0001_0002 = convertToIEEE754(json.REG0001, json.REG0002);
    if ((json.REG0005 !== undefined) && (json.REG0006 !== undefined))
        myJson.r0005_0006 = convertToIEEE754(json.REG0005, json.REG0006);
    if ((json.REG0009 !== undefined) && (json.REG0010 !== undefined))
        myJson.r0009_0010 = uint16x2_to_long(json.REG0009, json.REG0010);
    if ((json.REG0011 !== undefined) && (json.REG0012 !== undefined))
        myJson.r0011_0012 = convertToIEEE754(json.REG0011, json.REG0012);
    if (json.REG0071 !== undefined)
        myJson.r0071 = convertToBits(json.REG0071);
    if (json.REG0072 !== undefined)
        myJson.r0072 = convertToBits(json.REG0072);
    if (json.REG0095 !== undefined)
        myJson.r0095 = json.REG0095 * ( 2.5 / 4096 );
    if ((json.REG0105 !== undefined) && (json.REG0106 !== undefined))
        myJson.r0105_0106 = convertToIEEE754(json.REG0105, json.REG0106);
    if ((json.REG0361 !== undefined) && (json.REG0362 !== undefined))
        myJson.r0361_0362 = convertToIEEE754(json.REG0361, json.REG0362);
    if ((json.REG0363 !== undefined) && (json.REG0364 !== undefined))
        myJson.r0363_0364 = uint16x2_to_long(json.REG0363, json.REG0364);
    if ((json.REG0365 !== undefined) && (json.REG0366 !== undefined))
        myJson.r0365_0366 = uint16x2_to_long(json.REG0365, json.REG0366);
    return myJson;
}

// Orden debe ser manualmente sincronizado con parseNice
// export function parseNiceHeaders() {
//     // return ["flujo_instantaneo", "velocidad_flujo", "flujo_acumulado", "error_code_1", "error_code_2", "battery_power", "total_working_time"];
//     return [ "Nivel [m]", "Flujo Instantaneo [m3/h]", "Flujo Acumulado [m3] " ];
// }

function roundToN(num, cantidad_decimales) {    
    return +(Math.round(num + `e+${cantidad_decimales}`)  + `e-${cantidad_decimales}`);
}

export function parseNice(json) {
    let myJson = {};
    myJson.main = {};
    myJson.detail = {};

    myJson.main.nivel = roundToN(roundToN( 0.021552 * json.nivel,5) - 2.04744, 2);
    // myJson.main.nivel = json.nivel;
    

    myJson.main.flujo_instantaneo = null;
    if ((json.REG0001 !== undefined) && (json.REG0002 !== undefined))
        myJson.main.flujo_instantaneo = convertToIEEE754(json.REG0001, json.REG0002);
    myJson.main.flujo_instantaneo = roundToN(myJson.main.flujo_instantaneo,3);

    // myJson.velocidad_flujo = null;
    // if ((json.REG0005 !== undefined) && (json.REG0006 !== undefined))
    //     myJson.velocidad_flujo = convertToIEEE754(json.REG0005, json.REG0006);
    // myJson.velocidad_flujo = roundToN(myJson.velocidad_flujo,3);

    let parte_entera_flujo_acumulado = 0;
    if ((json.REG0009 !== undefined) && (json.REG0010 !== undefined))
        parte_entera_flujo_acumulado = uint16x2_to_long(json.REG0009, json.REG0010);
    let parte_decimal_flujo_acumulado = 0;
    if ((json.REG0011 !== undefined) && (json.REG0012 !== undefined))
        parte_decimal_flujo_acumulado = convertToIEEE754(json.REG0011, json.REG0012);
    myJson.main.flujo_acumulado = parseFloat(`${parte_entera_flujo_acumulado}.${parte_decimal_flujo_acumulado}`);

    // ============ DETALLE ================
    // Phase Voltaje
    myJson.detail.RMSUA = null;
    if ((json.PWR23 !== undefined) && (json.PWR24 !== undefined))
        myJson.detail.RMSUA = convertToIEEE754(json.PWR23, json.PWR24);
    myJson.detail.RMSUA = roundToN(myJson.detail.RMSUA,3);

    myJson.detail.RMSUB = null;
    if ((json.PWR25 !== undefined) && (json.PWR26 !== undefined))
        myJson.detail.RMSUB = convertToIEEE754(json.PWR25, json.PWR26);
    myJson.detail.RMSUB = roundToN(myJson.detail.RMSUB,3);

    myJson.detail.RMSUC = null;
    if ((json.PWR27 !== undefined) && (json.PWR28 !== undefined))
        myJson.detail.RMSUC = convertToIEEE754(json.PWR27, json.PWR28);
    myJson.detail.RMSUC = roundToN(myJson.detail.RMSUC,3);
    // Phase Current
    myJson.detail.RMSIA = null;
    if ((json.PWR35 !== undefined) && (json.PWR36 !== undefined))
        myJson.detail.RMSIA = convertToIEEE754(json.PWR35, json.PWR36);
    myJson.detail.RMSIA = roundToN(myJson.detail.RMSIA,3);

    myJson.detail.RMSIB = null;
    if ((json.PWR37 !== undefined) && (json.PWR38 !== undefined))
        myJson.detail.RMSIB = convertToIEEE754(json.PWR37, json.PWR38);
    myJson.detail.RMSIB = roundToN(myJson.detail.RMSIB,3);

    myJson.detail.RMSIC = null;
    if ((json.PWR39 !== undefined) && (json.PWR40 !== undefined))
        myJson.detail.RMSIC = convertToIEEE754(json.PWR39, json.PWR40);
    myJson.detail.RMSIC = roundToN(myJson.detail.RMSIC,3);
    // Phase Active Power
    myJson.detail.PA = null;
    if ((json.PWR41 !== undefined) && (json.PWR42 !== undefined))
        myJson.detail.PA = convertToIEEE754(json.PWR41, json.PWR42);
    myJson.detail.PA = roundToN(myJson.detail.PA,3);

    myJson.detail.PB = null;
    if ((json.PWR43 !== undefined) && (json.PWR44 !== undefined))
        myJson.detail.PB = convertToIEEE754(json.PWR43, json.PWR44);
    myJson.detail.PB = roundToN(myJson.detail.PB,3);

    myJson.detail.PC = null;
    if ((json.PWR45 !== undefined) && (json.PWR46 !== undefined))
        myJson.detail.PC = convertToIEEE754(json.PWR45, json.PWR46);
    myJson.detail.PC = roundToN(myJson.detail.PC,3);
    // Phase Power Factor
    myJson.detail.PFA = null;
    if ((json.PWR57 !== undefined) && (json.PWR58 !== undefined))
        myJson.detail.PFA = convertToIEEE754(json.PWR57, json.PWR58);
    myJson.detail.PFA = roundToN(myJson.detail.PFA,3);

    myJson.detail.PFB = null;
    if ((json.PWR59 !== undefined) && (json.PWR60 !== undefined))
        myJson.detail.PFB = convertToIEEE754(json.PWR59, json.PWR60);
    myJson.detail.PFB = roundToN(myJson.detail.PFB,3);

    myJson.detail.PFC = null;
    if ((json.PWR61 !== undefined) && (json.PWR62 !== undefined))
        myJson.detail.PFC = convertToIEEE754(json.PWR61, json.PWR62);
    myJson.detail.PFC = roundToN(myJson.detail.PFC,3);

    myJson.error = {};
    myJson.error.detail = false;
    Object.keys(myJson.detail).forEach(function (property) {
        if (isNaN(myJson.detail[property])) {
            myJson.error.detail = true;
        }
    });
    

    // myJson.error_code_1 = null;
    // if (json.REG0071 !== undefined)
    //     myJson.error_code_1 = convertToBits(json.REG0071);

    // myJson.error_code_2 = null;
    // if (json.REG0072 !== undefined)
    //     myJson.error_code_2 = convertToBits(json.REG0072);

    // myJson.battery_power = null;
    // if (json.REG0095 !== undefined)
    //     myJson.battery_power = json.REG0095 * ( 2.5 / 4096 );
    // myJson.battery_power = roundToN(myJson.battery_power,3);

    // myJson.total_working_time = null;
    // if ((json.REG0105 !== undefined) && (json.REG0106 !== undefined))
    //     myJson.total_working_time = convertToIEEE754(json.REG0105, json.REG0106)

    return myJson;
}

export function test_json() {
    return [{"minumero":"QWERTY123","ICCID":"123tyu567iop","payload":{"REG0001":0,"REG0002":0,"REG0003":0,"REG0004":-14143,"REG0005":15886,"REG0006":28003,"REG0007":-8231,"REG0008":0,"REG0009":0,"REG0010":0}},{"minumero":"QWERTY123","ICCID":"123tyu567iop","payload":{"REG0001":0,"REG0002":0,"REG0003":0,"REG0004":51393,"REG0005":15886,"REG0006":28003,"REG0007":57305,"REG0008":0,"REG0009":0,"REG0010":0}}];
}

export function test_json2() {
    return { "REG0000": 0,"REG0001": 0,"REG0004": 51393,"REG0005": 15886,"REG0008": 0,"REG0009": 0,"REG0010": 0,"REG0011": 0,"REG0012": 0,"REG0072": 0,"REG0095": 65120,"REG0104": 25728,"REG0105": 160,"REG0360": 32768,"REG0361": 17332,"REG0362": 17274,"REG0363": 5544,"REG0364": 38735,"REG0365": 50465 };
}
import React, {Component} from 'react';

import { IconContext } from "react-icons";
import { FaWhatsapp } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';

class WhatsappButton extends Component {   
    render() {
        return (
            <Button variant="success" href={`https://wa.me/${this.props.number}?text=${encodeURI(this.props.text)}`} >
                <IconContext.Provider value={{ color: "Green", className: "global-class-name" }}>
                    <div>
                    <FaWhatsapp /> Whatsapp
                    </div>
                </IconContext.Provider>
            </Button>
        );
    }
} 

export default WhatsappButton;
import React, {Component, Fragment} from 'react';
import './App.css';
import LoginView from './contenedores/LoginView';
import MenuView from './contenedores/MenuView';
import TableView from './contenedores/TableView';
import SideMenuView from './contenedores/SideMenuView';
import WhatsappButton from './contenedores/WhatsappButton';

import Image from 'react-bootstrap/Image';
import { rest_login, rest_getDashboard, rest_validateToken } from './restful';

import Cookies from 'universal-cookie';
var md5 = require('md5');

var cookie_duration_in_minutes = 60;
const cookies = new Cookies();

class App extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      token: cookies.get("token") || "",
      profile: {},
      dashboard: {},
      pozoSeleccionado: {},
      badPassword: false
    }
    this.onPozosChange = this.onPozosChange.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onLoginSubmit = this.onLoginSubmit.bind(this);
    this.onCloseSession = this.onCloseSession.bind(this);

    if(this.state.token !== ""){
      this.TokenValidation(this.state.token);
    }
  }
  
  onPozosChange(k) {
    if(k == 0) this.setState({pozoSeleccionado: {} });
    else {
      this.state.dashboard.forEach((element) => {
        if(element.id_pozo == k) this.setState({pozoSeleccionado: element });
      });
    }
  }

  onEmailChange(k) {
    this.setState({email: k });
  }

  onPasswordChange(k) {
    this.setState({password: k });
  }

  onCloseSession() {
    this.setState({profile: {} });
  }
  
  parseCredentials(result) {
    console.log(result);
    if(result.code === 200) {
      let message = result.values;
      this.setState({ profile: message });
      this.setState({ token: message.token });
      this.setState({ badPassword: false });

      let d = new Date();
      d.setTime(d.getTime() + (cookie_duration_in_minutes*60*1000));
      cookies.set("token", message.token, {path: "/", expires: d});
    }
    else if(result.code === 401) {
      this.setState({ badPassword: true });
    }
  }

  parseDashboard(result) {
    console.log(result);
    if(result.code === 200) {
      let message = result.values;
      this.setState({ dashboard: message });
    }
  }

  async onLoginSubmit() {    
    var raw = JSON.stringify({"email":this.state.email,"password":md5(this.state.password)});
    await rest_login(raw)
                    .then(result => this.parseCredentials(JSON.parse(result)) )
                    .catch(error => console.log('error', error));
      
    if (this.state.token !== "") {
      await rest_getDashboard(this.state.profile.token)
                     .then(result => this.parseDashboard(JSON.parse(result)) )
                      .catch(error => console.log('error', error));
    }
  }

  async TokenValidation(token) {    
    var raw = JSON.stringify({"token":token});
    await rest_validateToken(raw)
                    .then(result => this.parseCredentials(JSON.parse(result)) )
                    .catch(error => console.log('error', error));
      
    if (this.state.token !== "") {
      await rest_getDashboard(this.state.profile.token)
                     .then(result => this.parseDashboard(JSON.parse(result)) )
                      .catch(error => console.log('error', error));
    }
  }

  render() {
    return (
      <div id="outer-container" className="App-body">
        {/* <SideMenuView
          profile={this.state.profile}
          pageWrapId={ "page-wrap" }
          outerContainerId={ "outer-container" }
          onCloseSession={this.onCloseSession}
        /> */}
        <div style={{textAlign: "center"}}><Image src={require('./images/logo.png')} className="App-logo" fluid /></div>
        {!(Object.entries(this.state.profile).length === 0 && this.state.profile.constructor === Object) && (
          <div>
            Bienvenido {this.state.profile.nombre}
          </div>
        )}
        <main id="page-wrap">
        {(Object.entries(this.state.profile).length === 0 && this.state.profile.constructor === Object) ? (
          <LoginView profile={this.state.profile} badPassword={this.state.badPassword} onEmailChange={this.onEmailChange} onPasswordChange={this.onPasswordChange} onLoginSubmit={this.onLoginSubmit}/>
        ) : (
          <Fragment>
            <MenuView dashboard={this.state.dashboard} pozoSeleccionado={this.state.pozoSeleccionado} onPozosChange={this.onPozosChange} />
            <TableView pozoSeleccionado={this.state.pozoSeleccionado} token={this.state.token} />
          </Fragment>
        )}
        </main>
        {/* <WhatsappButton number="56984299584" text="Quiero automatizar mi pozo!" /> */}
      </div>
    );
  }
}

export default App;